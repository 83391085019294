import { FC, useCallback, useContext, useMemo } from "react";
import { Settings } from "react-slick";
import {
    BannersContext,
    CasinoScopesMap,
    FeedContext,
    UseCasinoGamesHookOptions,
    useSiteConfig,
} from "@finbackoffice/site-core";
import {
    CasinoGameTypeEnum,
    CasinoSectionsStyleEnum,
    HomePageSectionsEnum,
    ILayoutsHomePageSection,
    ISiteConfigLayouts,
} from "@finbackoffice/fe-core";
import dynamic from "next/dynamic";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import { IReelsDataState } from "types/reels.data";
import CasinoGames from "../casino/games/CasinoGames";
import PreloadComponent from "components/base/preload-component/PreloadComponent";
import styles from "./home.module.sass";
import HomeFAQ from "./faq/HomeFAQ";

const HomeTournamentItem = dynamic(() => import("./tournament/HomeTournamentItem"), {
    loading: () => <PreloadComponent style={{ height: 190, marginBottom: 8 }} />,
    ssr: false,
});

const Reels = dynamic(() => import("../casino/base/reels/Reels"), {
    loading: () => <PreloadComponent style={{ height: 240 }} />,
    ssr: false,
});

const BannersSlider = dynamic(() => import("../../base/banners-slider/BannersSlider"), {
    loading: () => <PreloadComponent style={{ height: 248, marginBottom: 8 }} />,
    ssr: false,
});

type IProps = {
    reels: IReelsDataState | null;
};

const Home: FC<IProps> = ({ reels }) => {
    const homePageConfig = useSiteConfig<ISiteConfigLayouts>("layouts").homePage;
    const { banners } = useContext(BannersContext);
    const { prematchTopLeagues } = useContext(FeedContext);
    const hasTopLeagues = prematchTopLeagues.length > 0;

    const bannerSettings: Settings = useMemo(
        () => ({
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 440,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: false,
                    },
                },
            ],
        }),
        [],
    );

    const renderSection = useCallback(
        (section: ILayoutsHomePageSection, index: number) => {
            if (section.enable) {
                switch (section.name) {
                    case HomePageSectionsEnum.Banners:
                        return (
                            <BannersSlider
                                key={index}
                                banners={banners}
                                sliderSettings={bannerSettings}
                                wrapperClassName={styles.homeBannerContainer}
                                imgProps={{
                                    width: 400,
                                    height: 270,
                                }}
                            />
                        );
                    case HomePageSectionsEnum.Slots:
                    case HomePageSectionsEnum.LiveCasino:
                        const gameTypes = section.type || CasinoScopesMap[section.name];
                        const commonOptions: UseCasinoGamesHookOptions = {
                            megaways: section.prop === "megaways",
                            bonusbuy: section.prop === "bonusbuy",
                            has_jackpot: section.prop === "has_jackpot",
                        };

                        if (section.search) {
                            commonOptions.gameName = section.search;
                        }

                        const getDefaultLabel = () => {
                            if (section.type === CasinoGameTypeEnum.LIVE_CASINO_TABLE) {
                                return {
                                    title: "home_livecasino_tables_title",
                                    sub_title: "home_livecasino_tables_head",
                                };
                            } else if (section.type === CasinoGameTypeEnum.LIVE_CASINO) {
                                return {
                                    title: "home_live_casino_title",
                                    sub_title: "home_live_casino_head",
                                };
                            } else if (section.name === HomePageSectionsEnum.Slots) {
                                return {
                                    title: "home_slots_title",
                                    sub_title: "home_slots_head",
                                };
                            }

                            return undefined;
                        };

                        return (
                            <CasinoGames
                                id={section.id}
                                key={index}
                                style={section.style}
                                wrapperClassName="homeWidget"
                                tag={section.tag}
                                gameTypes={gameTypes}
                                provider={section.provider || "All"}
                                options={{
                                    ...commonOptions,
                                }}
                                href={section.href}
                                label={getDefaultLabel()}
                                backImg={
                                    section.style === CasinoSectionsStyleEnum.RoundRow
                                        ? section.backImg
                                        : undefined
                                }
                                imgSrc={section.imgSrc}
                            />
                        );
                    case HomePageSectionsEnum.Reels:
                        return (
                            reels !== null && (
                                <Reels key={index} reels={reels} wrapperClassName="homeWidget" />
                            )
                        );
                    case HomePageSectionsEnum.Sport:
                        if (section.top_leagues_index && hasTopLeagues) {
                            return section.top_leagues_index.map((leagueIndex) => {
                                const tournament = prematchTopLeagues[leagueIndex - 1];
                                return tournament ? (
                                    <HomeTournamentItem
                                        key={tournament.id}
                                        tournament={tournament}
                                    />
                                ) : null;
                            });
                        }
                        break;
                    default:
                        return null;
                }
            }
            return null;
        },
        [bannerSettings, banners, hasTopLeagues, prematchTopLeagues, reels],
    );

    return (
        <>
            <FadeInAnimation>
                <div className={styles.middleAreaHomePage}>
                    {homePageConfig.widgets.map(renderSection)}
                </div>
            </FadeInAnimation>
            {homePageConfig.enableFAQ && <HomeFAQ />}
        </>
    );
};

export default Home;
