import { FC, PropsWithChildren, ReactNode } from "react";
import Footer from "components/footer/Footer";
import MainContent from "components/MainContent";

const CommonLayout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <MainContent>
            {children}
            <Footer />
        </MainContent>
    );
};

export const getCommonLayout = (page: ReactNode) => <CommonLayout>{page}</CommonLayout>;
