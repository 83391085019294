import classnames from "classnames";
import React, { FC, PropsWithChildren, useContext, useEffect } from "react";
import { ModalsContext } from "@finbackoffice/site-core";
import styles from "./main-content.module.sass";

type Props = {
    variant?: string;
};

const MainContent: FC<PropsWithChildren<Props>> = ({ children, variant }) => {
    const { isAnyModalOpened } = useContext(ModalsContext);

    useEffect(() => {
        if (isAnyModalOpened) {
            document.body.classList.add("disable-scroll");
        } else {
            document.body.classList.remove("disable-scroll");
        }
    }, [isAnyModalOpened]);

    return (
        <section
            className={classnames(styles.mainContent, variant && styles[variant])}
            id="mainContent">
            {children}
        </section>
    );
};

export default MainContent;
