import { GetServerSideProps, GetServerSidePropsContext, Page } from "next";
import { SiteContentScopes, PopupTags, TranslationScopes } from "@finbackoffice/enums";
import { HomePageSectionsEnum } from "@finbackoffice/fe-core";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Seo from "components/base/seo/Seo";
import { getCommonLayout } from "components/layout/common/CommonLayout";
import Home from "components/pages/home/Home";
import { usePrematchSports } from "hooks";
import {
    CommonSsrProps,
    clientBFFSingleton,
    createGetServerSideProps,
    getServerData,
    getServerRuntimeConfig,
    withSsrBanners,
    withSsrCasinoFavorites,
    withSsrReels,
} from "services/ssr";
import { getCurrentLocale } from "utils/helpers";
import { IReelsDataState } from "types/reels.data";

type SsrProps = {
    reels: IReelsDataState | null;
};

export const getServerSideProps: GetServerSideProps = createGetServerSideProps<
    SsrProps & CommonSsrProps
>(async (context: GetServerSidePropsContext) => {
    const runtimeConfig = await getServerRuntimeConfig();
    const client = clientBFFSingleton.getInstance(context.req.cookies.jwt);
    const locale = getCurrentLocale(runtimeConfig, context.locale);
    const isReelsWidgetEnabled = runtimeConfig.SITE_CONFIG.layouts.homePage.widgets.find(
        (widget) => widget.name === HomePageSectionsEnum.Reels && widget.enable,
    );

    const bannerScope = [SiteContentScopes.Homepage];

    const translationScope = [
        TranslationScopes.Home,
        TranslationScopes.Casino,
        TranslationScopes.CasinoLiveCasino,
        TranslationScopes.CasinoSlots,
        TranslationScopes.CasinoWidgets,
    ];

    if (runtimeConfig.SITE_CONFIG.layouts.subHeader.enable) {
        translationScope.push(TranslationScopes.SubHeader);
    }

    const [{ translations, configs, profile }, banners, casinoFavoriteGames, reels] =
        await Promise.all([
            getServerData(runtimeConfig, translationScope, context, client),
            withSsrBanners(runtimeConfig, client, locale, bannerScope),
            context.req.cookies.jwt ? withSsrCasinoFavorites(client) : undefined,
            isReelsWidgetEnabled ? withSsrReels(client, locale, bannerScope) : undefined,
        ]);

    return {
        props: {
            configs,
            runtimeConfig,
            jwt: context.req.cookies.jwt || "",
            profile,
            locale,
            translations,
            banners: banners.items,
            popupScope: [PopupTags.Allpages],
            casinoFavoriteGames: casinoFavoriteGames?.items || null,
            reels: isReelsWidgetEnabled ? reels || null : null,
        },
    };
});

const IndexPage: Page = ({ reels }: SsrProps) => {
    usePrematchSports();

    return (
        <ErrorBoundary name={Home.name}>
            <Seo page={TranslationScopes.Home} />
            <Home reels={reels} />
        </ErrorBoundary>
    );
};

IndexPage.getLayout = getCommonLayout;

export default IndexPage;
